import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography, Modal, Radio, Drawer } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined, CloseSquareFilled } from "@ant-design/icons";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import defaultImage from "../../../assets/images/no-image.svg";
import Back from "../../../assets/images/backIcon.svg";
import data from "../../../constants/restaurantPos.json";
import removeBg from "../../../assets/images/removebg.png";
import PlusIcon from "../../../assets/images/plusIconRestaurant.svg";
import minusIcon from "../../../assets/images/minusIconRestaurant.svg";
import DeleteProduct from "../../../assets/images/delete1.svg";
import addNote from "../../../assets/images/addNote.svg";
import DecrementImg from "../../../assets/images/Decrement2.svg";
import IncrementImg from "../../../assets/images/Increment2.svg";
import userIcon from "../../../assets/images/userId.svg";
import Close from "../../../assets/images/close-x.svg";
import DiscountIcon from "../../../assets/images/discountperIcon.svg";
import printBill from "../../../assets/images/printbillIcon.svg";
import Offers from "../../../assets/images/promoTag.svg";
import OrderListComponent from "./OrderListComponent";
import { v4 as uuidv4 } from "uuid";
import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";
import _ from "lodash";
import Keyboard from "react-simple-keyboard";
import TextArea from "antd/lib/input/TextArea";
import PointOfSaleRetailModals from "../Retail/RetailModals";
import { useTranslation } from "react-i18next";
import { getMultilingualText } from "../../../utility/getMultilingualText ";
import { useEventBus } from "../../../lib/hooks/EventBusProvider";
import printController from "../../../lib/printer/printController";
const MenuLayout = (props) => {
  const {
    setSelectedProductInCart,
    selectedProductId,
    setSelectedProductId,
    manualDiscountRestaurant,
    selectedSaleType,
    setSelectedSaleType,
    cart,
    restaurantProductCategory,
    productsData,
    pickProduct,
    decreaseProductQty,
    deleteProduct,
    increaseProductQty,
    handleOrder,
    handleVoid,
    addDefinedProductWithAddons,
    addProductWithAddons,
    setKeyValue,
    paymentVisible,
    setPaymentVisible,
    userPreferences,
    setDisplayCustomerSearch,
    categorieFlag,
    setCategorieFlag,
    setCart,
    showProducts,
    setShowProducts,
    setProductsData,
    saleTypeProducts,
    setSaleTypeProducts,
    requestPayment,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    setAmount,
    showOrderCompleteFlag,
    setShowOrderCompleteFlag,
    processOrder,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    handleAmount,
    handleCashPayment,
  } = props;
  const { t } = useTranslation();
  const { Text, Title } = Typography;
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemNotes, setSelectedItemNotes] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [notesPopup, setNotesPopup] = useState(false);
  const [categorieName, setCategorieName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [instructions, setInstructions] = useState(notesValue);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  // Add ONS Modal Starts //
  const [categoryLookup, setCategoryLookup] = useState({});
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { dispatchEvent } = useEventBus();

  const [isAllParkedState, setIsAllParkedState] = useState(false);

  useEffect(() => {
    setIsAllParkedState(cart.items.length > 0 && cart.items.every((item) => item.parkedItem === true));
  }, [cart.items]);

  useEffect(() => {
    setProductSearchInput("");
    setCategorieFlag(false);
  }, []);

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  const handleAddOnModal = (record, event) => {
    event.stopPropagation();
    setDisplayAddOnSelection(true);
    setSelectedProduct(record);

    const requiredListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 1);
    const optionsListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 0);
    setAddOnsList({
      requiredList: requiredListFilter,
      optionsList: optionsListFilter,
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }

    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  const handleAdd = async () => {
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        // productAddons: selectedAddons || [],
        selectedAddons: selectedAddons || [],
        weight: selectedProduct?.weight || 1,
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    handleAddOnModalClose();
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  // useEffect(() => {
  //   db.productCategories
  //     .toArray()
  //     .then((response) => {
  //       setProductCategoriesList(response);
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occur
  //       console.error(error);
  //     });
  // }, []);

  useEffect(() => {
    db.productCategories
      .toArray()
      .then((response) => {
        const result = response.filter((item) => {
          const filteredProducts = saleTypeProducts?.filter((product) => product?.mProductCategoryId === item?.mProductCategoryId);
          if (filteredProducts?.length > 0) {
            return item;
          }
        });
        setProductCategoriesList(result);
      })
      .catch((error) => {
        // Handle any errors that occur
        console.error(error);
      });
  }, [saleTypeProducts]);

  const showProductModal = (product) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (productCategoriesList?.length > 0) {
      const defaultCategory = productCategoriesList[0];
      setSelectedCategory(defaultCategory.mProductCategoryId);
      setCategorieName(defaultCategory.name);
      restaurantProductCategory(defaultCategory.mProductCategoryId);
    }
  }, [productCategoriesList]);

  useEffect(() => {
    if (productCategoriesList?.length > 0) {
      const defaultCategory = productCategoriesList[0];
      const filteredProducts = saleTypeProducts.filter((product) => product.mProductCategoryId === defaultCategory.mProductCategoryId);
      setProductsData(filteredProducts);
    } else {
      setProductsData([]);
    }
  }, [saleTypeProducts, productCategoriesList]);

  const cartItems = cart.items;
  // const cartItems = JSON.parse(localStorage.getItem("cartObj").items);
  // const cartObj = JSON.parse(localStorage.getItem("cartObj"));
  // const cartItems = cartObj ? cartObj.items : null;

  useEffect(() => {
    db.productCategories
      .toArray()
      .then((response) => {
        const lookup = response.reduce((acc, category) => {
          acc[category.mProductCategoryId.trim()] = category.name.trim();
          return acc;
        }, {});
        setCategoryLookup(lookup);
      })
      .catch((error) => {
        console.error("Error fetching product categories:", error);
      });
    const groupedProducts = cartItems?.reduce((acc, item) => {
      const categoryName = categoryLookup[item.mProductCategoryId.trim()] || "";
      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }
      acc[categoryName].push(item);
      return acc;
    }, {});

    setGroupedProducts(groupedProducts);
  }, [cartItems]);

  return (
    <>
      {paymentVisible ? (
        <OrderListComponent {...props} requestPayment={requestPayment} />
      ) : (
        <div style={{ display: "flex", overflow: "hidden" }}>
          <div style={{ width: cart.items.length > 0 ? "69%" : "100%", height: "100vh" }}>
            <Row style={{ padding: "2vh 1.5vw 0 1.5vw", fontSize: "1vw" }}>
              {/* <Col span={24}>
        <p style={{ fontWeight: 500, paddingLeft: "3px", fontSize: "1.25em", color: "#0F0718", paddingTop: "0.5em" }}>Order: {cart.documentno}</p>
       </Col> */}
              <Col span={24}>
                <p style={{ fontWeight: 500, paddingLeft: "3px", fontSize: "1.25em", color: "#0F0718", paddingTop: "0.5em" }}>
                  {showProducts ? (
                    <img
                      src={Back}
                      style={{ height: "4.5vh", cursor: "pointer" }}
                      onClick={() => {
                        setShowProducts(false);
                      }}
                    />
                  ) : null}{" "}
                  {getMultilingualText("Order")}: {cart.documentno}
                </p>
              </Col>
              <Col span={17}>
                <Input
                  placeholder={getMultilingualText("Search for products by code/name")}
                  id="sm-product-search"
                  onChange={(e) => {
                    setProductSearchInput(e.target.value);
                  }}
                  className="productSearch-sm"
                  style={{ borderRadius: "20px", padding: "0.8vh 0.8vw", borderColor: "#fff" }}
                  value={productSearchInput}
                  suffix={
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {productSearchInput.length > 0 ? (
                        <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856", cursor: "pointer" }} onClick={() => setProductSearchInput("")} />
                      ) : null}
                    </div>
                  }
                  prefix={
                    <img
                      src={SearchIcon}
                      alt=""
                      onClick={() => {
                        // onBarcodeInput(productSearchInput, false);
                      }}
                    />
                  }
                />
              </Col>
            </Row>
            <div className="tables-container-category">
              <Scrollbars autoHide style={{ height: "76vh" }}>
                <InfiniteScroll
                  dataLength={productCategoriesList.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <Row gutter={[16, 16]} style={{ marginTop: "1vh" }}>
                    {productCategoriesList
                      .filter((categorie) => (productSearchInput.length === 0 ? true : categorie.name.toLowerCase().includes(productSearchInput.toLowerCase())))
                      .map((categorie) => (
                        <Col
                          // span={cart.items.length > 0 ? 3 : 3}
                          key={categorie.mProductCategoryId}
                          style={{ display: "flex", justifyContent: "center", paddingRight: 0 }}
                        >
                          <Card
                            onClick={() => {
                              setSelectedCategory(categorie.mProductCategoryId);
                              setCategorieName(categorie.name);
                              restaurantProductCategory(categorie.mProductCategoryId);
                              setCategorieFlag(true);
                              setProductSearchInput("");
                            }}
                            style={{
                              // background: "#fff",
                              background: selectedCategory === categorie.mProductCategoryId ? "#2F3856" : "#fff",
                              height: "6vh",
                              borderRadius: "25px",
                              fontSize: "1vw",
                              cursor: "pointer",
                              display: "inline-block",
                              padding: "0 1em",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                fontSize: "1.1em",
                                fontWeight: "500",
                                // color: "#0F0718",
                                color: selectedCategory === categorie.mProductCategoryId ? "#fff" : "#929098",
                              }}
                            >
                              {categorie.name}
                            </span>
                          </Card>
                        </Col>
                      ))}
                  </Row>

                  <Row gutter={[16, 16]} style={{ marginTop: "3vh" }}>
                    {productsData
                      // .filter((product) => (productSearchInput.length === 0 ? true : product.name.toLowerCase().includes(productSearchInput.toLowerCase())))
                      .filter((product) => {
                        const searchInput = productSearchInput.toLowerCase();
                        const matchesName = product.name?.toLowerCase().includes(searchInput);
                        const matchesValue = product.value?.toLowerCase().includes(searchInput);
                        const matchesUPC = product.upc?.toLowerCase().includes(searchInput);
                        return productSearchInput.length === 0 || matchesName || matchesValue || matchesUPC;
                      })
                      .map((product) => (
                        <Col span={cart.items.length > 0 ? 6 : 4} key={product.id} onClick={() => {}}>
                          <Card
                            onClick={product?.productAddons?.length > 0 ? (e) => handleAddOnModal(product, e) : () => pickProduct(product)}
                            style={{
                              height: "28vh",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={product?.imageurl?.trim() || defaultImage}
                              style={{
                                height: "17vh",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "10px",
                                padding: "0.8vh 0.55vw",
                                width: "100%",
                              }}
                            />
                            <div
                              style={{
                                height: "7vh",
                                paddingLeft: "0.8vw",
                                fontWeight: 500,
                                fontSize: "1vw",
                                paddingRight: "0.8vw",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0",
                                  fontSize: "1em",
                                  fontWeight: 500,
                                  color: "#0F0718",
                                  height: "6vh",
                                }}
                              >
                                {product?.name}
                              </p>
                              <Row>
                                <Col
                                  span={21}
                                  style={{
                                    fontSize: "1.25em",
                                    fontWeight: "600",
                                    color: "#0F0718",
                                  }}
                                >
                                  {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                                  {product?.sunitprice}
                                  {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                                </Col>
                                <Col
                                  span={2}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "2px",
                                  }}
                                >
                                  <img src={removeBg} style={{ height: "1.8vh" }} />
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </InfiniteScroll>
              </Scrollbars>
            </div>
          </div>

          {cart.items.length > 0 ? (
            <div style={{ background: "#fff", height: "100vh", width: "31%", position: "relative", fontSize: "1vw" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "2vh 1vw 0 1vw" }}>
                <span style={{ fontWeight: 600, fontSize: "1.5em", color: "#0F0718" }}>{getMultilingualText("Order Details")}</span>
                <span style={{ fontWeight: 500, fontSize: "1em", color: "#929098" }}>{moment(cart.orderTime).format(userPreferences.dateFormat)}</span>
              </div>
              <p style={{ padding: "0 1vw", fontSize: "1.25em", color: "#0F0718", marginBottom: 0 }}>{cart.tableNo}</p>
              {tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId === cart?.customer?.cwrCustomerId ? (
                <button
                  style={{
                    marginLeft: "1.2vw",
                    color: "#0F0718",
                    backgroundColor: "#fff",
                    border: "1px solid #92909880",
                    fontSize: "0.85em",
                    borderRadius: "4px",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.2vh 0.5vw",
                    cursor: "pointer",
                  }}
                  onClick={() => setDisplayCustomerSearch(true)}
                >
                  <img src={userIcon} style={{ height: 15, width: "1.5vw", marginRight: "0.2vw" }} />
                  Add Customer
                </button>
              ) : (
                <button
                  style={{
                    marginLeft: "1.2vw",
                    color: "#0F0718",
                    backgroundColor: "#fff",
                    border: "1px solid #92909880",
                    fontSize: "0.85em",
                    borderRadius: "4px",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.2vh 0.5vw",
                    cursor: "pointer",
                  }}
                  onClick={() => setDisplayCustomerSearch(true)}
                >
                  <img src={userIcon} style={{ height: 15, width: "1.5vw", marginRight: "0.2vw" }} />
                  {cart?.customer?.name}
                </button>
              )}
              <hr style={{ color: "#92909880", width: "93%" }} />
              <Scrollbars
                autoHide
                style={{
                  // height: "46vh"
                  height: localStorage.getItem("dineIn") === "N" ? "46vh" : "46vh",
                }}
              >
                <InfiniteScroll
                  dataLength={cart.items.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <div>
                    {Object.keys(groupedProducts).map((category, index) => (
                      <div key={index}>
                        <Text style={{ fontSize: "1em", fontWeight: "500", paddingLeft: "0.8rem", color: "#0F0718" }}>
                          {category} ({groupedProducts[category].length})
                        </Text>
                        {groupedProducts[category].map((item) => (
                          <div style={{ padding: "5px 12px 5px 12px" }}>
                            <div
                              key={item.id}
                              style={{ fontSize: "1vw", backgroundColor: selectedProductId === item.lineId ? "#f0f0f0" : "transparent" }}
                              onClick={() => {
                                if (item.parkedItem) return;
                                if (localStorage.getItem("dineIn") === "N") {
                                  setSelectedProductId(item.lineId);
                                  setSelectedProductInCart(item);
                                }
                              }}
                            >
                              <Row>
                                <Col
                                  span={5}
                                  style={{
                                    // height: "10vh",
                                    // height: "11.5vh",
                                    height: item.description ? "12vh" : "10vh",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={item.imageurl || defaultImage}
                                    alt={item.name}
                                    style={{
                                      // height: "9.5vh",
                                      // height: "11vh",
                                      height: item.description ? "11vh" : "9.5vh",
                                      width: "5.5vw",
                                      objectFit: "cover",
                                      borderRadius: "7px",
                                    }}
                                  />
                                  <div
                                    onClick={async () => {
                                      if (item?.parkedItem) {
                                        const workflowRules = await db.POSWorkFlowRules.toArray();
                                        const approvers = await db.approvers.toArray();
                                        const cancelKotRule = workflowRules.find((res) => res.eventName === "Cancel KOT");

                                        if (cancelKotRule) {
                                          dispatchEvent(cancelKotRule.eventName, cancelKotRule.ruleName, cancelKotRule.ruleCondition, approvers, async (eventData) => {
                                            if (eventData?.approvalGranted) {
                                              deleteProduct(item);
                                            } else {
                                              // Modal.error({
                                              //   title: "Approval Required",
                                              //   content: "You need approval to delete this item.",
                                              // });
                                            }
                                          });
                                        }
                                      } else {
                                        deleteProduct(item);
                                      }
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: item.description ? "43px" : "35px",
                                      right: "6px",
                                      backgroundColor: "#fff",
                                      borderRadius: "20%",
                                      padding: "3px",
                                      cursor: "pointer",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                                    }}
                                  >
                                    <img
                                      src={DeleteProduct}
                                      alt="Delete"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col span={18} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.5vw", position: "relative" }}>
                                  <Row>
                                    <Col span={22}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <Text style={{ fontSize: "1em", fontWeight: "500", color: "#0F0718", marginRight: "0.4rem" }}>{item.name}</Text>
                                        <img
                                          src={addNote}
                                          style={{ width: "1rem", height: "1rem", cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedItem(item);
                                            setSelectedItemNotes(item);
                                            setNotesValue(item.notes || "");
                                            setNotesPopup(true);
                                          }}
                                          alt="Notes Icon"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Text style={{ fontSize: "0.8em", color: "#929098", fontWeight: 400 }}>{item.description}</Text>
                                  <Text style={{ fontWeight: 500, fontSize: "1.25em", color: "#0F0718", position: "absolute", bottom: 0 }}>
                                    {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                                    {item.nettotal.toFixed(item?.isQtyDesimal)}
                                    {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                                  </Text>

                                  <Space style={{ paddingLeft: "14vw", position: "absolute", bottom: 0 }}>
                                    <img
                                      src={minusIcon}
                                      height="25"
                                      width="25"
                                      onClick={async () => {
                                        if (item.parkedItem) {
                                          const workflowRules = await db.POSWorkFlowRules.toArray();
                                          const approvers = await db.approvers.toArray();
                                          const cancelKotRule = workflowRules.find((res) => res.eventName === "Cancel KOT");

                                          if (cancelKotRule) {
                                            dispatchEvent(cancelKotRule.eventName, cancelKotRule.ruleName, cancelKotRule.ruleCondition, approvers, async (eventData) => {
                                              if (eventData?.approvalGranted) {
                                                parseFloat(item.weight) > 1 ? decreaseProductQty(item) : deleteProduct(item);
                                              } else {
                                                // Modal.error({
                                                //   title: "Approval Required",
                                                //   content: "You need approval to delete this item.",
                                                // });
                                              }
                                            });
                                          }
                                        } else {
                                          parseFloat(item.weight) > 1 ? decreaseProductQty(item) : deleteProduct(item);
                                        }
                                      }}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                    <Text style={{ margin: "0 8px" }}>{item.weight}</Text>
                                    <img
                                      src={PlusIcon}
                                      height="25"
                                      width="25"
                                      onClick={() => {
                                        if (item.parkedItem) return;
                                        increaseProductQty(item);
                                      }}
                                      style={{
                                        cursor: item.parkedItem ? "not-allowed" : "pointer",
                                        opacity: item.parkedItem ? 0.5 : 1,
                                      }}
                                    />
                                  </Space>
                                </Col>
                              </Row>
                              {item.notes && item.notes.length > 0 && (
                                <Row>
                                  <Col span={20}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                      <p style={{ fontSize: "0.6rem", fontWeight: 400, color: "#929098", padding: 0, margin: 0 }}>Special Instructions: {item.notes}</p>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <span
                                style={{
                                  background: Math.abs(item.discount) > 0 ? "#F8C519" : "",
                                  borderRadius: "3px",
                                  padding: "0.25vw",
                                  display: item.discountName ? "" : "none",
                                  fontSize: "0.7em",
                                }}
                              >
                                {Math.abs(item.discount) > 0 ? item.discountName ? <img src={Offers} alt="" width={12} height={12} style={{ marginTop: "-2px" }} /> : "" : ""}{" "}
                                {Math.abs(item.discount) > 0 ? item.discountName || "" : ""}{" "}
                              </span>
                            </div>
                          </div>
                        ))}
                        <hr style={{ color: "#92909880", width: "92.5%" }} />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </Scrollbars>
              <div style={{ padding: "1vh 0.6vw", position: "absolute", bottom: "1vh", fontSize: "1vw" }}>
                <Space direction="vertical" style={{ width: "100%", background: "#F3F4F9", padding: "1vh 0.5vw", borderRadius: "10px", rowGap: "4px" }}>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>{getMultilingualText("Subtotal")}</Text>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {/* {(
                parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.total : cart.total).toFixed(2) -
                parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.tax : cart.tax).toFixed(2)
              ).toFixed(2)} */}
                      {(cart?.total - cart?.tax).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>{getMultilingualText("Discount Sales")}</Text>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {parseFloat(cart.discount).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>{getMultilingualText("Total Sales Tax")}</Text>
                    <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {parseFloat(cart.tax).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space style={{ width: "100%", justifyContent: "space-between", marginTop: "2px" }}>
                    <Text style={{ fontSize: "1.1em", color: "#0F0718", fontWeight: 600 }}>{getMultilingualText("Total Amount")}</Text>
                    <Text style={{ fontSize: "1.1em", color: "#0F0718", fontWeight: 600 }}>
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {/* {parseFloat(cart.total - cart.discount).toFixed(2)} */}
                      {parseFloat(cart.total).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                </Space>
                {/* {localStorage.getItem("dineIn") === "N" && ( */}
                {(isAllParkedState || localStorage.getItem("dineIn") === "N") && (
                  <div>
                    <Space direction="horizontal" style={{ paddingTop: "2vh", fontSize: "1vw" }}>
                      <Button
                        style={{
                          width: "13.5vw",
                          border: "1px solid rgba(146, 144, 152, 0.5)",
                          borderRadius: "10px",
                          color: "#2F3856",
                          height: "5.5vh",
                          fontSize: "1em",
                          fontWeight: "500",
                        }}
                        onClick={manualDiscountRestaurant}
                      >
                        <img src={DiscountIcon} style={{ paddingRight: "0.3rem", height: "2.5vh" }} />
                        <span>Add Discount</span>
                      </Button>

                      <Button
                        style={{
                          width: "13.5vw",
                          borderRadius: "10px",
                          color: "#2F3856",
                          height: "5.5vh",
                          fontSize: "1em",
                          fontWeight: "500",
                        }}
                      >
                        <img src={printBill} style={{ paddingRight: "0.3rem", height: "2.5vh" }} />
                        <span>Print Bill</span>
                      </Button>
                    </Space>
                    {/* <RetailModals {...props} /> */}
                  </div>
                )}
                <div>
                  <Space direction="horizontal" style={{ paddingTop: "2vh", fontSize: "1vw" }}>
                    {/* {localStorage.getItem("dineIn") === "Y" && ( */}
                    {posConfig?.allowCancelKOT === "Y" && (
                      // cart.length > 0 && cart.some((item) => item.parkedItem === true) &&
                      <Button
                        onClick={() => handleVoid()}
                        style={{
                          width: "7.8vw",
                          border: "1px solid rgba(146, 144, 152, 0.5)",
                          borderRadius: "10px",
                          color: "#2F3856",
                          height: "6vh",
                          fontSize: "1em",
                          fontWeight: "500",
                        }}
                      >
                        {getMultilingualText("Cancel")}
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        // if (localStorage.getItem("dineIn") === "N") {
                        if (isAllParkedState || localStorage.getItem("dineIn") === "N") {
                          setPaymentVisible(true);
                          // setKeyValue("3");
                        } else {
                          handleOrder();
                        }
                      }}
                      style={{
                        width: localStorage.getItem("dineIn") === "Y" && posConfig?.allowCancelKOT === "N" ? "27.5vw" : "19.5vw",
                        borderRadius: "10px",
                        backgroundColor: "#2F3856",
                        color: "#fff",
                        height: "6vh",
                        fontSize: "1em",
                        fontWeight: "500",
                      }}
                    >
                      {isAllParkedState || localStorage.getItem("dineIn") === "N" ? `${getMultilingualText("Pay Bill")}` : `${getMultilingualText("Send To Kitchen")}`}
                    </Button>
                    {/* localStorage.getItem("dineIn") === "N" || */}
                  </Space>
                </div>
              </div>
            </div>
          ) : null}

          {/* Modal for product details */}

          {/* modal for add on's starts */}

          <Modal
            closable={false}
            footer={null}
            bodyStyle={{ padding: 13, background: "#fff" }}
            visible={displayAddOnSelection}
            onCancel={handleAddOnModalClose}
            centered
            // width={
            //   (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 0) ||
            //   (addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1)
            //     ? "35%"
            //     : addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
            //     ? "48%"
            //     : "60%"
            // }
            width={"28%"}
          >
            <Row style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Col span={24} style={{ paddingBottom: 0, fontSize: "1vw" }}>
                <img src={selectedProduct?.imageurl} alt={selectedProduct?.name} style={{ width: "100%", borderRadius: "5px", height: "25vh", display: "block" }} />

                <div style={{ padding: "10px 5px 0 5px", fontSize: "1vw" }}>
                  <img src={removeBg} style={{ height: "1.9vh", marginBottom: "10px", marginTop: "0.6em" }} />

                  <span style={{ fontSize: "1.2em", fontWeight: "500", color: "#0F0718", paddingLeft: "0.7vw" }}>{selectedProduct?.name}</span>
                  <span style={{ fontSize: "1.2em", fontWeight: "600", color: "#0F0718", paddingLeft: "2.7vw" }}>
                    {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                    {selectedProduct?.sunitprice}
                    {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                  </span>
                </div>
                <p style={{ paddingLeft: "5px", paddingRight: "5px", color: "#929098", fontSize: "1em", marginBottom: 0 }}>{selectedProduct?.description}</p>
              </Col>

              <Row>
                <Col span={9} style={{ padding: 0 }} />
                <Col span={16} style={{ display: "flex", paddingLeft: "18.5vw", paddingTop: 0, paddingBottom: 0 }}>
                  <Button
                    icon={<MinusOutlined style={{ fontSize: "15px" }} />}
                    style={{
                      width: "100px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: 6,
                      marginRight: "15px",
                      paddingLeft: 6,
                      borderRadius: "5px",
                      paddingTop: 0,
                    }} // Set width, height, and center icon
                    onClick={() => handleQty("minus")}
                  />
                  <p style={{ fontSize: "1.25em", color: "#0F0718", fontWeight: 500 }}>{selectedProduct?.weight || 1}</p>
                  <Button
                    icon={<PlusOutlined style={{ fontSize: "15px", color: "#fff" }} />}
                    style={{
                      width: "100px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: 6,
                      marginLeft: "15px",
                      paddingLeft: 6,
                      backgroundColor: "#2F3856",
                      borderRadius: "5px",
                    }} // Set width, height, and center icon
                    onClick={() => handleQty("plus")}
                  />
                </Col>
              </Row>
            </Row>
            <p style={{ fontSize: "1.25em", color: "#0F0718", fontWeight: 500, marginBottom: 5, paddingLeft: "5px" }}>{getMultilingualText("Add On's")}</p>
            <Scrollbars autoHide className="addOnsModalScroll">
              <Row>
                <Col span={24} style={{ paddingRight: 3, paddingBottom: 10 }}>
                  <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                    {/* Required Add-Ons */}
                    {addOnsList?.requiredList?.length > 0 && (
                      <div style={{ fontSize: "1vw" }}>
                        <Radio.Group
                          value={_.intersection(addOnsList?.requiredList || [], selectedAddons)?.[0] || {}}
                          onChange={(e) => handleAddOnValue(e, "addOnRadio")}
                          style={{ width: "100%" }}
                        >
                          {addOnsList?.requiredList?.map((item) => (
                            <Card key={item.mProductAddOnId} style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                              <Row>
                                <Col span={24}>
                                  <Radio value={item} style={{ fontSize: "1vw" }}>
                                    <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                                  </Radio>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                        </Radio.Group>
                      </div>
                    )}

                    {addOnsList?.optionsList?.length > 0 && (
                      <div style={{ background: "#F3F4F9" }}>
                        {Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name")).map(([key, value]) => (
                          <div key={key} style={{ background: "#F3F4F9" }}>
                            {value?.map((item) => (
                              <Card key={item.mProductAddOnId} style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10, color: "#0F0718" }}>
                                <Row>
                                  <Col span={20}>
                                    <Checkbox
                                      checked={selectedAddons.findIndex((itemAddOn) => itemAddOn.mProductAddOnId === item.mProductAddOnId) !== -1}
                                      value={item}
                                      onChange={(e) => handleAddOnValue(e, "addOnCheck")}
                                    >
                                      <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                                    </Checkbox>
                                  </Col>
                                  <Col span={4}>
                                    <span style={data.restaurantPos.content.addOnSelectionModal.addOnPrice}>
                                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                      {item.price} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Scrollbars>

            <Row style={{}}>
              <Col span={24} style={{ fontSize: "1vw" }}>
                <Button style={data.restaurantPos.content.orderCart.checkoutBtn} className="addButton" onClick={handleAdd}>
                  <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>{getMultilingualText("Add to Cart")}</span>
                </Button>
              </Col>
            </Row>
          </Modal>

          <Drawer
            placement="bottom"
            height="53vh"
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "2vh",
                  fontSize: "1vw",
                }}
              >
                <p
                  style={{
                    fontSize: "1.5em",
                    marginTop: "3vh",
                    fontWeight: "500",
                    color: "#0F0718",
                    flex: 1,
                    textAlign: "center",
                  }}
                >
                  Add Notes for Item
                </p>
                <img
                  src={Close}
                  onClick={() => {
                    setNotesPopup(false);
                    setNotesValue("");
                  }}
                  style={{
                    marginLeft: "auto",
                    paddingBottom: "4vh",
                    paddingTop: "2vh",
                    cursor: "pointer",
                    width: "1.5vw",
                  }}
                />
              </div>
            }
            closeIcon={null}
            className="filter"
            bodyStyle={{ paddingTop: 0 }}
            visible={notesPopup}
            closable={true}
            onClose={() => {
              setNotesPopup(false);
            }}
            footer={null}
          >
            <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
              <Col
                span={11}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <TextArea id="notes-input" style={{ resize: "none" }} maxLength={200} onChange={(e) => setNotesValue(e.target.value)} value={notesValue} rows={9} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "0.8em",
                      color: "#929098",
                      fontWeight: 500,
                    }}
                  >
                    Max Characters : 200
                  </span>
                  <button
                    style={{
                      height: "5vh",
                      width: "10vw",
                      backgroundColor: "#2F3856",
                      color: "#fff",
                      border: "none",
                      borderRadius: "3px",
                      padding: "0 12px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setNotesValue("");
                      const updatedCartItems = cart.items.map((cartItem) => (cartItem.id === selectedItem.id ? { ...cartItem, notes: "" } : cartItem));
                      setCart({ ...cart, items: updatedCartItems });
                      if (selectedItem) {
                        setSelectedItem({ ...selectedItem, notes: "" });
                      }
                      setNotesPopup(false);
                    }}
                  >
                    Clear Notes
                  </button>
                </div>
              </Col>

              <Col span={12} offset={1}>
                <Keyboard
                  theme={"hg-theme-default aprvl"}
                  onKeyPress={(button) => {
                    if (button === "{backspace}") {
                      setNotesValue((prev) => prev.slice(0, -1));
                    } else if (button === "{space}") {
                      setNotesValue((prev) => prev + " ");
                    } else if (button === "{shift}" || button === "{lock}") {
                      handleShift();
                    } else if (button === "{number}") {
                      setLayoutName("number");
                    } else if (button === "{numbers}") {
                      setLayoutName("numbers");
                    } else if (button === "{small}") {
                      setLayoutName("shift");
                    } else if (button === "{caps}") {
                      setLayoutName("default");
                    } else if (button === "{enter}") {
                      if (selectedItem) {
                        selectedItem.notes = notesValue;
                        setInstructions(notesValue);
                        setNotesPopup(false);
                      }
                    } else {
                      setNotesValue((prev) => prev + button);
                    }
                  }}
                  layoutName={layoutName}
                  display={{
                    "{abc}": "ABC",
                    "{smileys}": "\uD83D\uDE03",
                    "{back}": "Enter",
                    "{numbers}": "123",
                    "{number}": "123",
                    "{bksp}": "backspace",
                    "{backspace}": "⌫",
                    "{shift}": "⇧",
                    "{tab}": "tab",
                    "{lock}": "lock",
                    "{enter}": "Enter",
                    "{space}": "Space",
                    "{caps}": "ABC",
                    "{small}": "abc",
                  }}
                  layout={
                    posConfig?.keyboardLanguage === "Y" && posConfig?.selectedKeyboardLanguage === "ar"
                      ? {
                          shift: ["ض ص ث ق ف غ ع ه خ ح ج د", "ش س ي ب ل ا ت ن م ك ط", "{shift} ئ ء ؤ ر ى ة و ز ظ أ إ آ ـ {backspace}", "{number} {space} {enter}"],
                          numbers: ["١ ٢ ٣ ٤ ٥ ٦ ٧ ٨ ٩ ٠", "! @ # $ % ^ & * ( )", "- . ، _ / ؛ ؟ {backspace}", "{caps} {space} {enter}"],
                          number: ["١ ٢ ٣ ٤ ٥ ٦ ٧ ٨ ٩ ٠", "! @ # $ % ^ & * ( )", "- . ، _ / ؛ ؟ {backspace}", "{small} {space} {enter}"],
                          default: ["ض ص ث ق ف غ ع ه خ ح ج د", "ش س ي ب ل ا ت ن م ك ط", "{shift} ئ ء ؤ ر ى ة و ز ظ أ إ آ ـ {backspace}", "{numbers} {space} {enter}"],
                        }
                      : {
                          shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                          numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                          number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                          default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
                        }
                  }
                />
              </Col>
            </Row>
          </Drawer>
          {/* {paymentVisible && <OrderListComponent {...props} />} */}
        </div>
      )}
    </>
  );
};

export default MenuLayout;
