import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Modal, message } from "antd";
import { useGlobalContext } from "../../lib/auth/globalStore";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { timeStamp } from "../../utility/timestamp";
import Axios from "axios";
import db from "../../database";
import { v4 as uuidv4 } from "uuid";
import { clock } from "../../utility/clock";
import "./loder.css";
import POSLogo from "../../assets/images/NewLogoCW.svg";
import leftLoading from "../../assets/images/opo.gif";
import "antd/dist/antd.css";
import "../style.css";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { processSync, syncProductsUom, syncDocTypes } from "../Sync/syncData";
import moment from "moment";

const Login = () => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const history = useHistory();
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  const clock1 = clock();
  const currentDateTime = new Date();
  const nameTill = tillValue.name;
  let email = "";
  let Enterprise = "";
  const authHeaders = getOAuthHeaders();
  let username;
  let cleanToken;
  if (authHeaders && authHeaders.access_token) {
    email = authHeaders.email;
    Enterprise = authHeaders.Enterprise;
    cleanToken = authHeaders.access_token;
    username = authHeaders.username;
  }

  useEffect(() => {
    (async () => {
      await onLogin(username, cleanToken);
    })();
  }, []);

  const insertTillOpenActivitity = (tillData) => {
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push("/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
        }
      });
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: timeStamp(),
        closingTime: null,
        openingCashDenominations: [
          {
            denomination: 1,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2,
            count: 0,
            amount: 0,
          },
          {
            denomination: 5,
            count: 0,
            amount: 0,
          },
          {
            denomination: 10,
            count: 0,
            amount: 0,
          },
          {
            denomination: 20,
            count: 0,
            amount: 0,
          },
          {
            denomination: 50,
            count: 0,
            amount: 0,
          },
          {
            denomination: 100,
            count: 0,
            amount: 0,
          },
          {
            denomination: 200,
            count: 0,
            amount: 0,
          },
          {
            denomination: 500,
            count: 0,
            amount: 0,
          },
          {
            denomination: 2000,
            count: 0,
            amount: 0,
          },
        ],
        closingCashDenominations: null,
        openingCash: 0,
        closingCash: null,
        totalOpeningAmount: 0,
        totalClosingExpectedAmount: null,
        totalClosingTransactions: null,
        totalClosingAmount: null,
      };
      localStorage.setItem("tillSession", JSON.stringify(openTillData));
      history.push("/pos");
    }
  };

  const onLogin = async () => {
    if (!username) return;

    const posConfigData = JSON.parse(localStorage.getItem("posConfigData"));
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    if (!tillData) {
      if (posConfigData?.posType === "PRD") history.push("/");
      return;
    }
    if (!tillData.tillAccess) return;

    const tillAccessIndex = Array.isArray(tillData?.tillAccess) ? tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === nameTill) : -1;

    if (tillAccessIndex !== -1) {
      tillData.tillAccess = tillData.tillAccess[tillAccessIndex];
    }

    tillData.time = moment().format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("username", username);
    localStorage.setItem("tillData", JSON.stringify(tillData));
    localStorage.setItem("documentSequence", tillData.tillAccess.cwrTill.nextAssignedNumber);
    localStorage.setItem("kioskUI", 0);

    if (posConfigData?.posType === "PRD") {
      if (posConfig?.enableProduction === "Y") {
        await syncProductsUom(tillData, authHeaders, tillValue.cwr_till_id, uuidv4().replace(/-/g, "").toUpperCase());
        await syncDocTypes();
        history.push("/production");
      } else if (posConfig?.enableProductionRMIssue === "Y") {
        await syncProductsUom(tillData, authHeaders, tillValue.cwr_till_id, uuidv4().replace(/-/g, "").toUpperCase());
        await syncDocTypes();
        history.push("/production-rm-issue");
      } else {
        message.error("Production or Production RMIssue is not enabled");
      }
    } else {
      await processSync(tillData, setGlobalStore, moment(localStorage.getItem("lastUpdatedTime")) ? "Full Sync" : "Incremental Refresh");

      if (posConfig?.showTillOpening === "Y") {
        if (tillData?.tillAccess?.salesRep === "Y") {
          history.push("/pos");
        } else {
          const redirectUrl = posConfig?.showDenominations === "Y" ? "/open-till" : "/open-till-component";
          history.push(redirectUrl);
        }
      } else {
        insertTillOpenActivitity(tillData);
      }
    }
  };

  const handleOK = () => {
    history.push("/");
    localStorage.removeItem("tillValue");
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
      onOk: handleOK,
    });
  };

  const formattedDateTime = currentDateTime.toLocaleDateString("en-US", {
    day: "2-digit", // Two-digit day of the month
    month: "2-digit", // Two-digit month
    year: "numeric", // Full year (e.g., 2023)
  });

  return (
    <div style={{ backgroundColor: "#F3F4F9", height: "100vh", width: "100%" }}>
      <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
        <Col span={6}>
          <img src={POSLogo} style={{ height: "2.8vw", paddingLeft: "1vw", paddingTop: "1vh" }} />
        </Col>
        <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: "1vw", fontSize: "1vw" }}>
          <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{formattedDateTime}</div>
          <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2em", color: "#0F0718" }}>{clock1}</div>
        </Col>
      </Row>
      <div style={{ display: "flex", height: "90vh" }}>
        <div
          style={{
            width: "48%",
            background: "#fff",
            height: "83vh",
            margin: "1.5%",
            marginRight: 0,
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "1vw" }}>
            <div style={{ position: "relative", width: "13vw", height: "13vw" }}>
              <img
                src={leftLoading}
                alt="Loading"
                style={{
                  width: "100%",
                  height: "100%",
                  // transform: `rotate(${rotation}deg)`,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "1.5em",
                  fontWeight: "500",
                  color: "#0F0718",
                }}
              >
                {percent}
              </div>
            </div>
            <p style={{ color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "2.5em", marginBottom: 0 }}>Till loading in process</p>
            {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
            <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
          </div>
        </div>
        <div style={{ width: "48%", height: "83vh", background: "#fff", margin: "1.5%", borderRadius: "10px" }}>
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "17vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Product Categories</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={productCategoryPercentage}
            status="active"
          />
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Products</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={productPercentage}
            status="active"
          />
          <p style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em", marginTop: "7vh", marginBottom: 0, padding: "0 2.5vw", color: "#0F0718" }}>Promotions</p>
          <Progress
            strokeColor={{
              from: "#2F3856",
              to: "#2F3856",
            }}
            // showInfo={false}
            style={{ padding: "0 2.5vw" }}
            percent={promotionPercentage}
            status="active"
          />
        </div>
      </div>
    </div>
    // <Spin indicator={LoadingOutlinedSpin} spinning={loading.status} style={{ marginTop: "38vh", marginLeft: "50%" }}></Spin>
  );
};

export default Login;
